<template>
  <div class="home">
    <div class="header top_header" v-if="brand == 'Xiaomi'">
      <VanIcon @click="black" class="arrow" name="arrow-left" />
      <p>邀请好友赚VIP</p>
      <span class="rule" @click="popup">活动规则</span>
    </div>
    <div class="header" v-else>
      <VanIcon @click="black" class="arrow" name="arrow-left" />
      <p>邀请好友赚VIP</p>
      <span class="rule" @click="popup">活动规则</span>
    </div>
    <div class="banner">
      <img class="img" :src="inviteInfo.thumb">
      
      <VanPopup class="show show1" closeable v-model="show1">
        <div class="h2">
          <img class="img" src="@/assets/images/title.png"/>
        </div>
        <div class="cont" v-html="inviteInfo.share_rule_desc">

        </div>
      </VanPopup>
    </div>
    <div class="invite">
      <div class="videologin">
        <img class="img" :src="inviteInfo.videologin_url"/>
      </div>
      <div class="qrcodeBox">
        <div class="qrcode" ref="qrcode"></div>
      </div>
      <div class="txt">
        <div class="p">
          邀请链接：<span>{{inviteInfo.share_url}}</span><a @click="copy(inviteInfo.share_url)">复制</a>
        </div>
        <div class="button1" @click="android">保存图片</div>
      </div>
    </div>
    <div class="xiaomi_award" v-if="brand == 'Xiaomi'">
      <div class="gift"><img class="img" src="@/assets/images/gift.png"/></div>
      <div class="text">
        <div class="h3">邀请奖励</div>
        <div class="p">当前已邀请{{listInfo.total}}人</div>
        <div class="span" v-for="(itm,idx) in inviteInfo.reward_rules" :key="idx">成功邀请{{itm.invite_num}}人，送{{itm.days_num}}天VIP</div>
      </div>
      <div class="button" @click="showPopup">邀请名单 ></div>
      <VanPopup class="show show2" closeable v-model="show">
         <div class="h2">
          <img class="img" src="@/assets/images/title1.png"/>
         </div>
         <div class="p">
           共{{listInfo.total}}人
         </div>
         <div class="table">
           <div class="th">
             <span>用户id</span>
             <span>日期</span>
           </div>
           <div class="tableBox">
             <div class="tb" v-for="(item,index) in listInfo.list" :key="index">
             <span>{{item.uid}}</span>
             <span>{{formatDate(item.register_time*1000)}}</span>
           </div>
           </div>
         </div>
      </VanPopup>
    </div>
    <div class="award" v-else>
      <div class="gift"><img class="img" src="@/assets/images/gift.png"/></div>
      <div class="text">
        <div class="h3">邀请奖励</div>
        <div class="p">当前已邀请{{listInfo.total}}人</div>
        <div class="span" v-for="(itm,idx) in inviteInfo.reward_rules" :key="idx">成功邀请{{itm.invite_num}}人，送{{itm.days_num}}天VIP</div>
      </div>
      <div class="button" @click="showPopup">邀请名单 ></div>
      <VanPopup class="show show2" closeable v-model="show">
         <div class="h2">
          <img class="img" src="@/assets/images/title1.png"/>
         </div>
         <div class="p">
           共{{listInfo.total}}人
         </div>
         <div class="table">
           <div class="th">
             <span>用户id</span>
             <span>日期</span>
           </div>
           <div class="tableBox">
             <div class="tb" v-for="(item,index) in listInfo.list" :key="index">
             <span>{{item.uid}}</span>
             <span>{{formatDate(item.register_time*1000)}}</span>
           </div>
           </div>
         </div>
      </VanPopup>
    </div>
    <!-- <div class="bottom"> -->
      <img class="img" style="height:243px" src="@/assets/images/post1.png"/>
      <img class="img" style="height:250px" src="@/assets/images/post2.png"/>
    <!-- </div> -->
  </div>
</template>

<script>
import {
  Empty as VanEmpty,
  Icon as VanIcon,
  List as VanList,
  Overlay as VanOverlay,
  Popup as VanPopup,
  Tab as VanTab,
  Tabs as VanTabs,
  Toast,
} from 'vant';
import http from "@/http/request"
import QRCode from "qrcodejs2";

export default {
  name: 'Home',
  components: {
    VanTabs,
    VanTab,
    VanEmpty,
    VanIcon,
    VanOverlay,
    VanList,
    VanPopup,
  },
  data() {
    return {
      show: false,
      show1:false,
      inviteInfo:[],
      listInfo:[],
      brand:''
    };
  },
  created() {
    let user ={
      uid:this.GetQueryString("uid"),
      token: this.GetQueryString("token"),
      appid: this.GetQueryString("appid"),
      sign: this.GetQueryString("sign"),
    }
    let _this = this
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    http.all([
      http.post("/v1/user/getFrontShareInfo", user,),
      http.post("v1/user/getFrontInvitationList",user)
    ]).then(http.spread(function(inviteInfo,list) {
      Toast.clear();
      if (inviteInfo.code == 0) {
        _this.inviteInfo = inviteInfo.info
        new QRCode(_this.$refs.qrcode, {
			    width: 90,
			    height: 90, // 高度
			    text: inviteInfo.info.share_url
		  });
      } else {
        Toast(inviteInfo.msg)
      }
      if(list.code == 0){
        _this.listInfo = list.info
      }else{
        Toast(list.msg)
      }
    })).catch((err) => {
      Toast.clear();
      Toast("获取数据失败！")
      console.log('FAIL', err)
    });
  },
  computed: {
  },
  methods: {
    GetQueryString(name){
      let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return  unescape(r[2]); return null;
    },
    black(){
      AndroidClient.backToHome()
    },
    showPopup() { 
      this.show = true;
    },
    popup(){
      this.show1= true
    },
    formatDate(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + MM + '-' + d
    },
    copy(text) {
      this.$copyText(text).then(() => {
				Toast('复制成功')
			}, function (e) {
				console.log(e);
			})
		},
    android(){
      AndroidClient.saveShareImage();
    }
  },
  mounted() {
    let MobileDetect = require('mobile-detect');
    let md = new MobileDetect(navigator.userAgent);
      // 品牌
    this.brand = md.mobile();
  }
}
</script>
<style lang="scss">
.home{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  background: #141028;
  .header{
    padding: 20px 0 10px 0;
    display: flex;
    background: url(../assets/images/topbg.png) no-repeat;
    background-size:100% 100% ;
    line-height: 50px;
    position: relative;
    p{
      font-size: 18px;
      color: #FFFFFF;
      display: block;
      padding-left: 80px;
    }
    .arrow{
      color: #fff;
      line-height: 50px;
      padding-left: 20px;
      margin-top: 1px;
    }
    .rule{
      width: 60px;
      height: 23px;
      background: linear-gradient(131deg, #A536FF 0%, #7123FB 100%);
      border-radius: 11px;
      display: block;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      line-height: 23px;
      position: absolute;
      right: 16px;
      top: 32px;
    }
  }
  .top_header{
    margin-bottom: 40px;
    padding-top: 10px;
    background: none;
    .rule{
      top: 22px;
      line-height: 25px;
    }
  }
  .img{
      width: 100%;
      height: 100%;
      display: block;
  }
  .banner{
    width: 100%;
    position: relative;
  }
  .award{
    width: 350px;
    margin: 20px auto;
    background: url(../assets/images/bg2.png);
    background-size:100% 100% ;
    display: flex;
    padding-top: 5px;
    .gift{
      width: 54px;
      height: 54px;
      padding:38px 20px;
    }
    .text{
      text-align: center;
      padding-bottom: 15px;
      .h3{
        font-size: 18px;
        color: #F2E8A7;
        padding: 25px 0 2px 0;
      }
      .p{
        font-size: 12px;
        color: #B0B0E3;
        padding-bottom: 18px;
      }
      .span{
        font-size: 14px;
        color: #DBDEEA;
        padding-bottom: 2px;
      }
    }
    .button{
      width: 70px;
      height: 25px;
      background: #47487D;
      border-radius: 14px;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      line-height: 25px;
      margin: 25px 0 0 15px;
      
    }
  }
  .xiaomi_award{
    width: 350px;
    margin: 20px auto;
    background: url(../assets/images/bg2.png);
    background-size:100% 100% ;
    display: block;
    padding-top: 5px;
    .gift{
      width: 54px;
      height: 54px;
      padding:38px 20px;
      float: left;
    }
    .text{
      text-align: center;
      padding-bottom: 15px;
      float: left;
      .h3{
        font-size: 18px;
        color: #F2E8A7;
        padding: 38px 0 2px 0;
      }
      .p{
        font-size: 12px;
        color: #B0B0E3;
        padding-bottom: 18px;
      }
      .span{
        font-size: 14px;
        color: #DBDEEA;
        padding-bottom: 2px;
      }
    }
    .button{
      width: 70px;
      height: 25px;
      background: #47487D;
      border-radius: 14px;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      line-height: 25px;
      margin: 38px 0 0 15px;
      float: left;
    }
  }
  .invite{
    background: url(../assets/images/bg.png) no-repeat;
    background-size: 100% 100%;
    width: 350px;
    margin: 10px auto;
    min-height: 400px;
    .videologin{
      width: 95px;
      height: 28px;
      padding: 22px 12px;
    }
    .qrcodeBox{
      padding: 18px;
      img{
        padding: 5px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 5px;
      }
    }
    .txt{
      background: linear-gradient(179deg, rgba(158,115,225,0.00) 0%, #372585 83%);
      padding-bottom: 18px;
      border-radius: 8px;
      .p{
        display: flex;
        font-size: 14px;
        color: #FFFFFF;
        padding: 100px 20px 18px 12px;
        span{
         color: #FF4C4C;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         width: 60%;
        //  flex-grow:1;
        //  display: block;
        }
        a{
          width: 55px;
          height: 24px;
          line-height: 24px;
          font-size: 13px;
          color: #FFFFFF;
          border: 1px solid #fff;
          text-align: center;
          border-radius: 5px;
          position: relative;
          top: -5px;
        }
      }
    }
    .button1{
      width: 325px;
      height: 38px;
      background: linear-gradient(156deg, #E934BA 0%, #2768DF 100%);
      border-radius: 18px;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 38px;
      text-align: center;
      margin: 0 auto;
    }
  }
  .show{
    width: 75%;
    height: 430px;
    background: url(../assets/images/popbg.png) no-repeat 0 0/100% 100%;
    box-shadow: inset 0 1px 5px 0 #A190FC;
    border-radius: 10.56px;
    margin: 0 auto;
    overflow:visible ;
    .van-popup__close-icon{
      width: 20px;
      height: 20px;
      border: 1px solid #dfdfdf;
      border-radius: 50%;
      font-size: 10px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      top: -50px;
      right: 0px;
      z-index: 9999;
      position: fixed;
    }
    .h2{
      width: 50%;
      margin: 25px auto 10px auto;
    }
  }
  .show1{
    .cont{
      overflow: auto;
      height: 350px;
      padding: 0px 15px 0px 15px;
    }
  }
  .show2{
    .p{
      font-size: 12px;
      color: #BEBEF1;
      text-align: center;
    }
    .table{
      width: 86%;
      margin: 12px auto;
      overflow: hidden;
      height: 75%;

      .tableBox{
        height: 88%;
        overflow: hidden;
        overflow-y:auto;
      } 
      .th{
        display: flex;
        background: #563DA7;
        border: 1px solid #979797;
        border-radius: 2px;
        span{
          display: block;
          width: 50%;
          font-size: 12px;
          color: #FFFFFF;
          text-align: center;
          padding: 10px 0;
        }
        span:nth-child(1){
          border-right: 1px solid #979797;
        }
      }
      .tb{
        display: flex;
        padding: 18px 10px;
        justify-content: space-between ;
        border-bottom:1px solid #979797 ;
        background: #7853CC;
        span{
          display: block;
          width: 50%;
          font-size: 12px;
          color: #FFFFFF;
        }
        span:nth-child(2){
          text-align: right;
        }
      }
      .tb:last-child{
        border-bottom: none;
      }
    }
  }
}
</style>
